import Blocks from "@/components/Blocks"
import styles from "@/blocks/theme/name-collection/name-collection.module.css"
import Share from "@/components/common/social/share"
import { usePropsObject } from "@/lib/contexts/appContext"

export default function CollectionInfo({ block }) {
  const settings = {
    "core/heading": { width: "bleed" },
    "core/paragraph": { width: "bleed" }
  }

  const object = usePropsObject()

  return (
    <section
      className={`${styles.collectionInfo} flex flex-col lg:max-w-full mx-auto w-full`}>
      <Blocks blocks={block.innerBlocks} settings={settings} />
      {block.attrs?.sharing?.show && (
        <>
          <span className="mb-3 font-sans text-base font-bold tracking-wider uppercase">
            {block.attrs?.sharing?.headline ?? "Dela"}
          </span>
          <Share
            classes="w-icon-xl h-icon-xl mb-8 mr-3"
            url={object.url + "#" + block.attrs?.sharing?.id}
            label={false}
            wrapper="flex-row"
            iconSize="large"
            hidePrint={true}
          />
        </>
      )}
    </section>
  )
}
